import $ from "jquery";
import Swiper from "swiper/bundle";

export const homeProductsCarousel = () => {
  $(document).ready(function () {
    const swipers = [];

    $(".product-card-swiper").each(function () {
      const swiperInstance = new Swiper(this, {
        slidesPerView: "auto",
        speed: 400,
        spaceBetween: 20,
        navigation: {
          nextEl: ".next-btn",  // Default navigation buttons
          prevEl: ".prev-btn",  // Default navigation buttons
        },
        on: {
          init: function () {
            // Manually trigger update when Swiper is initialized
            updateButtonStates(this);
          },
          navigationNext: function () {
            updateButtonStates(this);
          },
          navigationPrev: function () {
            updateButtonStates(this);
          },
          navigationHide: function () {
            // Hide buttons when navigation is hidden
            updateButtonStates(this);
          },
          navigationShow: function () {
            // Show buttons when navigation is shown
            updateButtonStates(this);
          },
          resize: function () {
            updateButtonStates(this);
          },
        },
      });

      swipers.push(swiperInstance);
    });

    function updateButtonStates(swiper) {

      const $swiperContainer = $(swiper.el).closest(".products_carousel_block_container");

      const $secondNextButton = $swiperContainer.find(".second-next-btn");
      const $secondPrevButton = $swiperContainer.find(".second-prev-btn");


      if ($(swiper.navigation.nextEl).hasClass('swiper-button-disabled')) {
        $secondNextButton.addClass('swiper-button-disabled');
      } else {
        $secondNextButton.removeClass('swiper-button-disabled');
      }
    
      // Check if the default prev button is disabled
      if ($(swiper.navigation.prevEl).hasClass('swiper-button-disabled')) {
        $secondPrevButton.addClass('swiper-button-disabled');
      } else {
        $secondPrevButton.removeClass('swiper-button-disabled');
      }
    }

    $(".second-next-btn").each(function (index) {
      $(this).click(function () {
        if (swipers[index]) {
          swipers[index].slideNext(400); // Slide next
          swipers[index].emit('navigationNext'); // Manually trigger navigationNext event
        }
      });
    });

    $(".second-prev-btn").each(function (index) {
      $(this).click(function () {
        if (swipers[index]) {
          swipers[index].slidePrev(400); // Slide previous
          swipers[index].emit('navigationPrev'); // Manually trigger navigationPrev event
        }
      });
    });
  });
};
