import $ from "jquery";
export const dynamicHero = () => {
    $(function () {
        const heroHeadlineHeight = $(".main-container.hero-headline").height();
        if (heroHeadlineHeight > 580) {
            $(".hero-background-image.hero-banner").css("height", heroHeadlineHeight + 50);
        }

    });
};
