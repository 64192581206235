import $ from "jquery";

export const PdpTabs = () => {

    const lineMover = () => {

        let activeIndex = $('.tabs__list .tabs__item.active').index();

        // Initialize a variable to store the total width
        let elementBeforeIndexWidth = 0;

        // Loop through each item before the active item
        $('.tabs__list .tabs__item').each(function (index) {
            // Check if the current item is before the active item
            if (index < activeIndex) {
                // Add the width of the current item to the total width
                elementBeforeIndexWidth += $(this).outerWidth(true); // Including margins
            } else {
                // Break the loop when reaching the active item
                return false;
            }
        });


        const positionOfline = elementBeforeIndexWidth + activeIndex * 16; // 16 is the gap between items

        //get the width of the item
        const widthItem = $('.tabs__item.active').width();

        const line = $('.tabs__list span');

        // Animate line movement
        line.stop().animate({
            left: positionOfline + 'px',
            width: widthItem + 'px'
        }, {
            duration: 200,
            easing: 'swing',
            start: function () {
                // Animation start callback
            },
            complete: function () {
                // Animation complete callback
            }
        });

    };

    const initializeTabs = () => {
        //hide all tab content areas...
        $('.tab-item-content').hide();
        // show the first tab content area by default
        $('.tab-item-content').first().show();
        $('.tabs__item').first().addClass('active');
        $('.tabs__item').click(function (e) {
            $('.tabs__item').removeClass('active');
            $(this).addClass('active');
            if (!$(e.target).is('a')) {
                $('.tab-item-content').removeClass('active').fadeOut();
            }
            // Get the index of the clicked tab
            let tabIndex = $(this).index();

            // Show the corresponding tab content based on the index with fadeInRight animation
            $('.tab-item-content').eq(tabIndex).addClass('active').fadeIn("slow");
            lineMover();
        });
        lineMover();
    };

    // Call initializeTabs to set up the tabs initially
    initializeTabs();

    lineMover();
};
